<!-- 货币兑换 -->
<template>
  <div class="CurrencyExchange">
    <p style="
        text-align: left;
        font-size: 30px;
        margin-top: 0;
        margin-bottom: 10px;
      ">
      {{$t('currency.exchange_es_ES')}}
    </p>
    <el-form ref="ruleForm" :rules="rules" :model="listQuery" label-width="120px" label-position="right" style="text-align: left">
      <el-form-item :label="$t('operation.button.login')+(':')">
        {{ this.userCode }}
      </el-form-item>
      <el-form-item :label="$t('fiShopAcTemp.currencyCode_es_ES')+(':')" prop="acType">
        <el-select v-model="listQuery.acType" style="width: 100%" @change="acTypeChange(listQuery.acType)" :placeholder="$t('mi.input.select')">
          <el-option value="" />
            <!--       本位币钱包     -->
<!--          <el-option :label="$t('Base.currency.wallet')" value="B$"></el-option>-->
            <!--       奖金钱包  电子钱包    -->
          <el-option :label="$t('bonus.wallet')" value="FB"></el-option>
            <!--       名车基金     -->
<!--          <el-option :label="$t('ac_type.B2')" value="B2"></el-option>-->
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('available')+(':')">
        <div v-if="listQuery.acType">
          {{ yue }}&nbsp;&nbsp;
<!--          <span v-if="listQuery.acType === 'B$'">{{this.wallet}}</span>-->
<!--          <span v-else-if="listQuery.acType === 'FB'">{{this.electronics}}</span> -->
          <span style="margin-left: 10px;font-size: 18px">W$</span>
        </div>
      </el-form-item>
      <el-form-item :label="$t('target.currency_es_ES')" prop="toAcType">
        <el-select v-model="listQuery.toAcType" placeholder="" style="width: 100%">
          <el-option value="" />
          <el-option v-if="listQuery.acType === 'FB' || listQuery.acType === 'B2'" :label="$t('Base.currency.wallet')" value="B$"></el-option>
<!--          <el-option v-if="listQuery.acType === 'B$' || listQuery.acType === 'B2'"  :label="$t('bonus.wallet')" value="FB"></el-option>-->
<!--          <el-option  :label="$t('bvPointChange.updatePoint')" value="H0"></el-option>-->
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Account.money')+(':')" prop="money">
        <el-row>
          <el-col :span="8">
            <el-input v-model="listQuery.money" @input="inputnum"  />
          </el-col>
          <el-col :span="2">
            <span style="margin-left: 10px;font-size: 18px">W$</span>
<!--            <span> {{this.symbol}}</span>-->
<!--            <span v-if="listQuery.acType === 'B$'">{{this.wallet}}</span>-->
<!--            <span v-else-if="listQuery.acType === 'FB'">{{this.electronics}}</span>-->
          </el-col>
          <el-col :span="8">
            <el-input disabled v-model="this.rate" />
          </el-col>
          <el-col :span="2">
            <span style="margin-left: 10px;font-size: 18px">MXN</span>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- <el-form-item :label="$t('E-wallet.balance.after.operation') +(':')">
        <el-row>
          <el-col :span="14">
            <span v-if="listQuery.acType">{{ yue - this.listQuery.money * rate }}</span>
          </el-col>
          <el-col :span="8">
            <span v-if="listQuery.acType === 'B$'">₽</span>
            <span v-else-if="listQuery.acType === 'FB'">$</span>
          </el-col>
        </el-row>
      </el-form-item> -->
      <el-form-item :label="$t('Notice')+(':')">
        <el-input type="textarea" v-model="listQuery.memo"></el-input>
      </el-form-item>
      <el-form-item :label="$t('transaction.password')+(':')" prop="password">
        <el-input type="password" v-model="listQuery.password"></el-input>
      </el-form-item>
      <el-button v-loading="saveLoading" type="warning" plain style="float: right; margin-bottom: 10px" @click="btnImplement('ruleForm')">{{$t('btn.implement')}}</el-button>
    </el-form>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import { getExRateAndY, saveOrUpdateFiAcEXChange } from "@/api/rus/api"

  export default {
    name: "CurrencyExchange",
    data () {
      return {
        symbol:null,
        wallet:null,
        electronics:null,
        saveLoading: false,
        yue: '',
        FBrate:0,
        listQuery: {
          acType: '',
          toAcType: '',
          money: 0,
          memo: null,
          password: '',
          changeType: ''
        },
        rules: {
          acType: [{ required: true, message: this.$t('po.setInfo'), trigger: ["change", "blur"] }],
          toAcType: [{ required: true, message: this.$t('po.setInfo'), trigger: ["change", "blur"] }],
          money: [{ required: true, message: this.$t('po.setInfo'), trigger: ["change", "blur"] }],
          password: [{ required: true, message: this.$t('po.setInfo'), trigger: ["change", "blur"] }]
        },
      }
    },
    computed: {
      ...mapGetters([
        'userCode',
        'companyCode',
      ]),
      rate(){
        if(this.listQuery.money){
          return (this.listQuery.money * this.FBrate).toFixed(2)
        }
        return 0
      }
    },
    created() {
      this.getSymbol()
    },
    methods: {
      inputnum(){
        this.listQuery.money = this.listQuery.money.replace(/^(-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
      },
      getSymbol(){
        if(this.companyCode === 'RUS'){
          this.symbol = 'Руб'
          this.electronics = '$'
          this.wallet = '₽'
        }else if (this.companyCode === 'MNT'){
          // this.symbol = '₮'
          this.wallet = '₮'
        }else if(this.companyCode === 'KZT'){
          // this.symbol = '₸'
          this.wallet = '₸'
        }else if(this.companyCode === 'USD'){
          // this.symbol = '$'
          this.wallet = '$'
        }
      },
      acTypeChange (val) {
        this.listQuery.toAcType = (val==='B2' || val==='FB') ? "B$" : ""
        this.listQuery.money = 0
        getExRateAndY({ 'acType': val }).then(res => {
          this.yue = res.data.yue
          this.FBrate = res.data.rate
        })
      },
      getRate(){
        getExRateAndY({ 'acType': 'FB' }).then(res => {
          // this.rate = res.data.rate * this.listQuery.money
          if (this.listQuery.acType==='B$') {
            this.rate = (this.listQuery.money / res.data.rate).toFixed(2)
          } else if (this.listQuery.acType==='FB') {
            this.rate = (this.listQuery.money * res.data.rate).toFixed(2)
          } else {
            this.rate = this.listQuery.money
          }
        })
      },
      btnImplement (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$confirm(this.$t('fi.sure.handle'), this.$t('common.tips'), {
              confirmButtonText: this.$t('button.confirm'),
              cancelButtonText: this.$t('operation.button.cancel'),
              customClass: 'msgBox',
              type: 'warning'
            }).then(() => {
            this.saveLoading = true
            this.listQuery.changeType = this.listQuery.acType === 'B$' ? 1 : 0
            saveOrUpdateFiAcEXChange({ ...this.listQuery })
              .then(() => {
                this.$router.push({ path: 'currency-record' })
                this.saveLoading = false
              })
              .catch(() => {
                this.saveLoading = false
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: this.$t('operation.button.cancel')
              })
            })
          }
        })
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>
